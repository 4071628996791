function formatTime(value) {
  var date = new Date(value);
  const hour = date.getHours() > 9 ? date.getHours() : '0' + date.getHours()
  const minute = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()
  const second = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds()
  return `${hour}:${minute}:${second}\n${date.getMonth() + 1}-${date.getDate()}`
}
export function getOptions(params) {
  const { leftData, name, leftM = {} } = params
  const leftName = leftM ? leftM.name : ''
  const leftUnit = leftM ? leftM.unit : ''
  let min = null
  let max = null
  if (params.leftM && params.leftM.value_range) {
    const arr = params.leftM.value_range.split(',')
    min = function (val) {
      return Math.min(arr[0], val.min)
    }
    max = function (val) {
      return Math.max(arr[1], val.max)
    }
  }
  return {
    title: {
      text: name,
      left: 'center'
    },
    grid: {
      top: 40
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
          formatter: function (params) {
            if (params.axisDimension === 'x') {
              return formatTime(params.value)
            } else {
              return isNaN(params.value) ? '-' : Number(params.value).toFixed(2)
            }
          }
        }
      },
      formatter(val) {
        let str = ''
        val.forEach((v) => {
          const { value = [], seriesName = '' } = v
          const floatValue = !isNaN(value[1]) ? parseFloat(value[1] || 0).toFixed(2) : '-'

          str += `
                    <div>
                        ${v.marker}
                        <span>${seriesName}</span>
                        <span>${floatValue}</span>
                        <span>${leftUnit}</span>
                    </div>
                `
        })
        return str
      }
    },
    xAxis: {
      type: 'time',
      axisLabel: {
        formatter: function (value) {
          return formatTime(value)
        }
      },
    },
    yAxis: [
      {
        min,
        max,
        type: 'value',
        axisTick: {
          show: false//不显示坐标轴刻度线
        },
        splitLine: {
          show: false//不显示网格线
        },
        axisLabel: {
          show: false,//不显示坐标轴上的文字,
        },
        name: leftUnit,
      }
    ],
    dataZoom: [
      {
        show: true,
        realtime: true,
        start: 0,
        end: 100
      }
    ],
    markPoint: {
      data: [
        { type: 'max', name: '最大值' },
        { type: 'min', name: '最小值' }
      ]
    },
    series: [
      {
        data: leftData,
        showSymbol: false,
        type: 'line',
        step: leftM.type === 'BOOL' ? 'middle' : undefined,
        smooth: false,
        name: leftName,
        animation: false
      }
    ]
  }
}

export function findIndexOfTimeRange(time, rangeArray) {
  let index = rangeArray.findIndex(v => v.toString() === time.toString())
  if (index < 0) { // 没有找到
    for (let i = 0; i < rangeArray.length; i++) {
      if ((parseFloat(rangeArray[i]) < parseFloat(time)) && (parseFloat(rangeArray[i + 1]) > parseFloat(time))) {
        index = i
        break
      }
    }
  }
  return index
}
