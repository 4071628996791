<template>
  <div class="chartExplore">
    <div class="header">
      <div class="text">选择设备</div>
      <div class="filters">
        <van-field
          readonly
          clickable
          name="picker"
          :value="value"
          placeholder="选择设备"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            ref="pick"
            show-toolbar
            :columns="columns"
            @confirm="onselectDevice"
            @cancel="showPicker = false"
          >
          </van-picker>
        </van-popup>
      </div>
    </div>
    <div class="header">
      <div class="text">选择指标</div>
      <div class="filters">
        <van-field
          readonly
          clickable
          name="picker"
          :value="zb_value1"
          placeholder="选择指标"
          @click="showPickerzb1 = true"
        />
        <van-popup v-model="showPickerzb1" position="bottom">
          <van-picker
            ref="pick"
            show-toolbar
            :columns="mappingData"
            @confirm="onselectTarget"
            @cancel="showPickerzb1 = false"
          >
          </van-picker>
        </van-popup>
      </div>
    </div>
    <div class="header">
      <div class="text">实时更新</div>
      <div class="filters">
        <van-switch v-model="updateRealTime" size="24" />
      </div>
    </div>
    <div class="header">
      <div class="text">起始日期</div>
      <div class="filters">
        <van-cell :value="startTime" @click="show = true" />
        <van-calendar
          v-model="show"
          @confirm="startonConfirm"
          :min-date="minDate"
        />
      </div>
    </div>
    <div class="header">
      <div class="text">起始时间</div>
      <div class="filters">
        <van-cell :value="startmint" @click="startDate = true" />
      </div>
    </div>
    <template v-if="!updateRealTime">
      <div class="header">
        <div class="text">结束日期</div>
        <div class="filters">
          <van-cell
            :value="endTime"
            @click="endshow = true"
            :min-date="minDate"
          />
          <van-calendar v-model="endshow" @confirm="endonConfirm" />
        </div>
      </div>
      <div class="header">
        <div class="text">结束时间</div>
        <div class="filters">
          <van-cell :value="endmint" @click="endDate = true" />
        </div>
      </div>
    </template>
    <div class="header">
      <div class="text">快捷选择</div>
      <div class="filters">
        <div class="tim">
          <van-button plain type="info" size="mini" @click="gettime(10)"
            >最近十分钟</van-button
          >
          <van-button plain type="info" size="mini" @click="gettime(60)"
            >最近一小时</van-button
          >
          <van-button plain type="info" size="mini" @click="gettime(0)"
            >最近一天</van-button
          >
        </div>
      </div>
    </div>

    <div id="line-chart"></div>
    <!--日期选择弹框-->
    <van-popup v-model="startDate" position="bottom">
      <van-datetime-picker
        type="time"
        @cancel="onCancelDate"
        @confirm="startonConfirmDate"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
      />
    </van-popup>

    <van-popup v-model="endDate" position="bottom">
      <van-datetime-picker
        type="time"
        @cancel="onCancelDate"
        @confirm="endonConfirmDate"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
      />
    </van-popup>
    <div style="position: fixed; top: 10rem; width: 100%">
      <van-loading type="spinner" vertical color="#1989fa" v-if="loading" />
    </div>
  </div>
</template>

<script>
import { Getchart, GetdeviceData, Overview, Getdevice } from "@/api/index";
import echarts from "echarts";
import { getOptions } from "./util";
import Moment from "moment";

export default {
  name: "echats",
  data() {
    return {
      minDate: new Date(1900, 0, 1),
      endTime: "",
      endshow: false,
      show: false,
      endmint: "00:00",
      endmintshpw: false,
      mintshow: false,
      loading: false,
      startmint: "00:00",
      device_id: "",
      code: "",
      zb_type: "",
      name: "",
      unit: "",
      chartData: [],
      columns: [],
      value: "",
      zb_value1: "",
      // zb_value2: "",
      zb_id1: "",
      zb_id2: "",
      showPicker: false,
      showPickerzb1: false,
      showPickerzb2: false,
      maxDate: new Date(2099, 10, 1),
      startCurrentDate: new Date(Date.now() - 24 * 60 * 60 * 1000),
      endCurrentDate: new Date(),
      startDate: false,
      endDate: false,
      startTime: null,
      mappingData: [],
      timer: null,
      isprohibit: false,
      isoldtime: false,
      oldstart: null,
      oldend: null,
      updateRealTime: true,
    };
  },
  watch: {
    updateRealTime(v) {
      this.endTime = Moment().format("YYYY-MM-DD");
      this.endmint = Moment().format("HH:mm");
      if (!v) {
        this.clearTimer()
      } else {
        this.getPointHistory()
      }
    }
  },
  created() {
    this.startTime = Moment().subtract().format("YYYY-MM-DD");
    this.endTime = Moment().format("YYYY-MM-DD");
    this.startmint = Moment().subtract(60, "minutes").format("HH:mm:ss");
    this.endmint = Moment().format("HH:mm:ss");
  },
  mounted() {
    this.$nextTick(() => {
      this.getDeviceList();
      // this.getDeviceList()
      // this.initLineChart()
      // this.getPointHistory()
    });
  },
  beforeDestory() {
    clearInterval(this.timer);
    this.clearTimer()
  },
  beforeRouteLeave(from, to, next) {
    clearInterval(this.timer);
    this.clearTimer()
    next()
  },
  methods: {
    mintstartonConfirm() { },
    startonConfirm(date) {
      this.startTime = Moment(date).format("YYYY-MM-DD");
      this.show = false;
      this.getPointHistory();
    },
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    gettime(time) {
      this.isoldtime = true;
      this.endmint = Moment().format("HH:mm");
      if (time === 60) {
        this.startTime = Moment().subtract(60, "minutes").format("YYYY-MM-DD");
        this.startmint = Moment().subtract(60, "minutes").format("HH:mm");
        this.oldstart = Moment()
          .subtract(60, "minutes")
          .format("YYYY-MM-DD HH:mm:ss");
        this.oldend = Moment().format("YYYY-MM-DD HH:mm:ss");
      }
      if (time === 10) {
        this.startTime = Moment().subtract(10, "minutes").format("YYYY-MM-DD");
        this.startmint = Moment().subtract(10, "minutes").format("HH:mm");
        this.oldstart = Moment()
          .subtract(10, "minutes")
          .format("YYYY-MM-DD HH:mm:ss");
        this.oldend = Moment().format("YYYY-MM-DD HH:mm:ss");
      }
      if (time === 0) {
        //当前时间的前1天时间
        this.startTime = Moment().subtract(1, "days").format("YYYY-MM-DD");
        this.startmint = Moment().subtract(1, "days").format("HH:mm");
        this.oldstart = Moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss");
        this.oldend = Moment().format("YYYY-MM-DD HH:mm:ss");
      }
      // this.startonConfirmDate()
      this.getPointHistory();
    },
    getDeviceList() {
      this.isoldtime = false;
      this.loading = true;
      const data = {
        limit: 1000,
        offset: 0,
      };
      // 获取设备信息
      Overview(data).then((response) => {
        this.loading = false;
        const datalist = response.data.results.map((item) => {
          return { ...item, text: item.device_name };
        });
        this.columns = datalist;
        this.value = datalist[0].text;
        this.device_id = datalist[0].id;
        this.initTargets();
      });
    },
    onselectDevice(value) {
      this.value = value.text;
      this.device_id = value.id;
      this.showPicker = false;
      this.initTargets();
    },
    onselectTarget(value) {
      this.showPickerzb1 = false;
      this.isprohibit = value.is_explore;
      this.zb_value1 = value.text;
      this.zb_id1 = value.code;
      this.zb_type = value.type;
      this.getPointHistory();
    },
    // 获取设备分类列表标题
    initTargets() {
      this.loading = true;
      const data = {
        limit: 1000,
        offset: 0,
        q: "",
        device_id: this.device_id,
        show_disabled: this.isprohibit,
      };
      Getdevice(data).then((response) => {
        this.loading = false;
        const a = response.data.results.map((v) => ({
          ...v,
          code: v.ali_identifier,
          name: v.display_name || v.ali_name,
          unit: v.display_unit || v.ali_unit,
        }));

        const datalist = a.map((item) => {
          return { ...item, text: item.name };
        });
        const realData = datalist.filter(
          (item) => item.type === "REAL" && item.is_explore
        );
        const boolData = datalist.filter(
          (item) => item.type === "BOOL" && item.is_explore
        );
        const fdatalist = [...realData, ...boolData];
        this.mappingData = fdatalist;
        if (fdatalist.length > 0) {
          this.zb_value1 = this.mappingData[0].text;
          // this.zb_value2 = this.mappingData[1].text;
          this.zb_type = this.mappingData[0].type;
          this.zb_id1 = this.mappingData[0].code;
          this.zb_id2 = this.mappingData[1].code;
        } else {
          this.zb_value1 = '';
          this.zb_type = '';
          this.zb_id1 = '';
          this.zb_id2 = '';
          this.$toast('暂无指标数据')
        }
        this.getPointHistory();
      });
    },
    // 日期组件自定义格式
    formatter(type, value) {
      if (type === "year") {
        this.value1 = value; // 可以拿到当前点击的数值
        return `${value}年`;
      } else if (type === "month") {
        this.value2 = value;
        return `${value}月`;
      } else if (type === "day") {
        this.value3 = value;
        return `${value}日`;
      } else if (type === "hour") {
        this.value4 = value;
        return `${value}时`;
      }
      this.value5 = value;
      return `${value}分`;
    },
    onCancelDate() {
      this.startDate = false;
      this.endDate = false;
    },
    getLocalTime(nS) {
      return Moment(new Date(parseInt(nS))).format("YYYY-MM-DD");
      // return new Date(parseInt(nS)).toLocaleDateString();
    },
    startonConfirmDate(date) {
      this.startmint = date;
      this.startDate = false;
      this.getPointHistory();
    },
    endonConfirm(date) {
      this.endTime = Moment(date).format("YYYY-MM-DD");
      this.endshow = false;
      this.getPointHistory();
    },
    endonConfirmDate(date) {
      this.endmint = date;
      this.endDate = false;
      this.getPointHistory();
    },
    // 获取折线图数据
    async getPointHistory() {
      if (this.mappingData.length === 0) {
        this.clearChart()
        return
      }
      try {
        this.chartData = [];
        this.chartVisible = true;
        let postData = {};
        if (this.isoldtime) {
          postData = {
            device_id: this.device_id,
            metrics: [this.zb_id1, this.zb_id2],
            start: Moment(this.oldstart).format(),
            end: Moment(this.oldend).format(),
            show_disabled: this.isprohibit,
          };
        } else {
          const start = this.startTime + " " + this.startmint;
          const end = this.endTime + " " + this.endmint;
          postData = {
            device_id: this.device_id,
            metrics: [this.zb_id1, this.zb_id2],
            start: Moment(start).format(),
            end: Moment(end).format(),
            show_disabled: this.isprohibit,
          };
        }
        this.loading = true;
        await Getchart(postData).then((res) => {
          const leftMapping = this.mappingData.find(v => v.code === this.zb_id1) || {}
          const rightMapping = this.mappingData.find(v => v.code === this.zb_id2) || {}

          this.initChart(res.data, leftMapping, rightMapping)
          this.loading = false;
          this.tryFetchLineChart(postData)
        })

      } catch (e) {
        console.log(e);
      } finally {
        this.chartVisible = false;
      }
    },
    getStartEnd() {
      let start = this.startTime + " " + this.startmint;
      let end = new Date();
      if (this.updateStart && this.chartInstance) {
        start = this.updateStart.start = Moment(this.updateStart.end) + 1000
        end = this.updateStart.end = Moment(this.updateStart.end) + 5000
      } else {
        this.updateStart = { start, end }
      }
      start = Moment(this.updateStart.start).format()
      end = Moment(this.updateStart.end).format()
      return { start, end }
    },
    async tryFetchLineChart(postData) {
      this.clearTimer()
      if (this.updateRealTime) {
        this.updateTimer = setInterval(async () => {
          const { start, end } = this.getStartEnd()
          postData.start = start
          postData.end = end
          this.loading = true;
          await Getchart(postData).then((res) => {
            const leftMapping = this.mappingData.find(v => v.code === this.zb_id1) || {}
            const rightMapping = this.mappingData.find(v => v.code === this.zb_id2) || {}
            if (!this.chartInstance) {
              this.initChart(res.data, leftMapping, rightMapping)
            } else {
              this.updateLineChart(res.data, leftMapping, rightMapping)
            }
            this.loading = false
          })
        }, 1000 * 5)
      }
    },
    clearTimer() {
      this.updateTimer && clearInterval(this.updateTimer)
      this.updateTimer = null
      this.chartInstance = null
    },
    clearChart() {
      this.clearTimer()
      if (!this.chartInstance) {
        this.chartInstance = echarts.init(
          document.getElementById("line-chart")
        );
      }
      const options = getOptions({
        leftData: [],
        name: "",
        leftM: []
      });
      this.chartInstance.setOption(options, true);
    },
    updateLineChart(result = []) {
      if (result.length > 0) {
        const ops = this.chartInstance.getOption()
        const series = ops.series
        series.forEach((element, idx) => {
          const v = result[idx]
          const unit = v.unit
          for (const key in v.dps) {
            let keyVal = key === null ? '-' : parseFloat(key)
            let value = v.dps[key]
            if (v.type === 'BOOL') {
              let val1 = value === null ? '-' : Math.round(parseFloat(value))
              element.data.push([keyVal, val1, unit])
            } else {
              let val2 = value === null ? '-' : parseFloat(value).toFixed(2)
              element.data.push([keyVal, val2, unit])
            }
          }
        });
        this.chartInstance.setOption({
          series
        });
      }
    },
    initChart(data = [], leftM) {
      // if (!data.length) return
      const left = data.find((v) => v.metric === this.zb_id1);
      // const right = data.find((v) => v.metric === this.zb_id2);
      const leftData = [];
      // const rightData = [];
      if (left) {
        for (const key in left.dps) {
          if (this.zb_type === "BOOL") {
            leftData.push([
              parseFloat(key),
              left.dps[key] !== null ? Math.round(parseFloat(left.dps[key])) : '-',
            ]);
          } else {
            leftData.push([
              parseFloat(key),
              left.dps[key] !== null ? parseFloat(left.dps[key]).toFixed(2) : '-',
            ]);
          }
        }
      }
      // if (right) {
      //   for (const key in right.dps) {
      //     if (this.zb_type === "BOOL") {
      //       rightData.push([
      //         parseFloat(key),
      //         Math.round(parseFloat(right.dps[key])),
      //       ]);
      //     } else {
      //       rightData.push([
      //         parseFloat(key),
      //         parseFloat(right.dps[key]).toFixed(2),
      //       ]);
      //     }
      //   }
      // }

      if (!this.chartInstance) {
        this.chartInstance = echarts.init(
          document.getElementById("line-chart")
        );
      }
      const options = getOptions({
        leftData,
        name: "",
        leftM
      });
      this.chartInstance.setOption(options, true);
    },
    // initLineChart(data = {}, name, unit = "") {
    //   const values = [];
    //   for (const key in data) {
    //     values.push([parseFloat(key), parseFloat(data[key]).toFixed(2)]);
    //   }
    //   if (!this.lineChart) {
    //     this.lineChart = echarts.init(document.getElementById("line-chart"));
    //   }
    //   this.chartData = values;
    //   const option = {
    //     title: {
    //       text: name,
    //       show: false,
    //     },
    //     grid: {
    //       left: "3%",
    //       right: "4%",
    //       bottom: "3%",
    //       containLabel: true,
    //     },
    //     xAxis: [
    //       {
    //         type: "time",
    //         splitLine: {
    //           show: false,
    //         },
    //       },
    //     ],
    //     yAxis: [
    //       {
    //         type: "value",
    //         name: unit,
    //       },
    //     ],
    //     dataZoom: [
    //       {
    //         show: true,
    //         realtime: true,
    //         start: 0,
    //         end: 100,
    //       },
    //     ],
    //     series: [
    //       {
    //         name,
    //         type: "line",
    //         stack: "总量",
    //         lineStyle: {
    //           color: "rgba(24, 144, 255, 1)",
    //         },
    //         showSymbol: false,
    //         areaStyle: {
    //           color: {
    //             type: "linear",
    //             x: 0,
    //             y: 0,
    //             x2: 0,
    //             y2: 1,
    //             colorStops: [
    //               {
    //                 offset: 0,
    //                 color: "rgba(53, 195, 255, 1)", // 0% 处的颜色
    //               },
    //               {
    //                 offset: 1,
    //                 color: "rgba(24, 144, 255, 1)", // 100% 处的颜色
    //               },
    //             ],
    //             global: false, // 缺省为 false
    //           },
    //         },
    //         smooth: true,
    //         data: values,
    //       },
    //     ],
    //   };
    //   this.lineChart.setOption(option, true);
    //   this.getGetdeviceData();
    //   this.timer = setInterval(() => {
    //     // 某些定时器操作
    //     this.getGetdeviceData();
    //   }, 10000); // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
    // },
    // getGetdeviceData() {
    //   GetdeviceData({ device_id: this.device_id, metrics: [] }).then(
    //     (response) => {
    //       const res = response.data;
    //       const temp = (res || []).find((v) => v.metric === this.code);
    //       if (temp) {
    //         this.updateChart(temp);
    //       }
    //     }
    //   );
    // },
    // updateChart(node) {
    //   const data = [
    //     ...this.chartData,
    //     [node.timestamp, typeof node.value === "number" ? node.value : 0],
    //   ];
    //   if (this.lineChart) {
    //     this.lineChart.setOption({
    //       series: [
    //         {
    //           data,
    //         },
    //       ],
    //     });
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
.chartExplore {
  // -webkit-tap-highlight-color: transparent;
  // user-select: none;
  // position: relative;
  // transform: rotate(90deg);
  // transform-origin: bottom left;
  // position: absolute;
  // top: -100vw;
  // height: 100vw;
  // width: 100vh;
  padding: 0.4rem 0.1rem;
  padding-bottom: 55px;

  .van-cell {
    padding: 0.1rem 0.4rem;
    font-size: 13px;
  }

  .header {
    background: #f3f3f3;
    padding: 0.4rem 1rem;
    display: flex;
    justify-content: flex-start;

    .text {
      width: 30%;
      text-align: right;
    }

    .filters {
      width: 70%;
      margin-left: 10px;
    }
  }

  .times {
    width: 60%;
    display: flex;
    //line-height: 1.6rem;
    //height: 1.6rem;
  }

  .tim {
    display: flex;
    justify-content: space-between;
    flex: 1;
    //margin-left: 0.4rem;
    margin-top: 1px;

    .van-button {
      width: 75px;
      line-height: 1.6rem;
      height: 1.6rem;
      padding-top: 1px;
      margin-top: -1px;
      border-radius: 5px;
    }
  }

  #line-chart {
    height: 300px;
    width: 100%;
  }
}
</style>
